html:lang(lt) .lang-en, html:lang(lt) :lang(en), html:lang(en) .lang-lt, html:lang(en) :lang(lt) {
  display: none;
}

h5.date, h5.location, h5.date-location {
  color: #f6c97b;
}

h5.date-location {
  margin-top: 1em;
}

.parralax {
  perspective: 1px;
  scroll-behavior: smooth;
  height: 100vh;
  overflow: hidden auto;
}

header.top, header.top div.top-inner, header.top:after, header.top div.top-shadow, header.top div.top-inner:before {
  height: 220px;
}

header.top {
  transform: translateY(var(--scrollPosTop));
  z-index: 10;
  width: 100%;
  transition: all .2s ease-in-out;
  position: sticky;
  top: 0;
}

header.top:hover {
  transform: translateY(0);
}

header.top div.top-inner {
  text-align: center;
  box-sizing: border-box;
  z-index: 12;
  pointer-events: none;
  xbackground-position: calc(var(--scrollPosition) / -125) calc(var(--scrollPosition) / -200);
  background-color: #1f1d1b;
  background-image: url("../images/starry-2560-50-kai-pilger-Ef6iL87-vOA-unsplash.11901d5b.webp");
  background-position: center;
  background-repeat: repeat;
  background-size: auto;
  background-origin: border-box;
  background-position: calc(var(--scrollPosition) / -1200) calc(var(--scrollPosition) / -200);
  width: 100%;
  position: relative;
  overflow: hidden;
  mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 90 100 90'><path d='m 0 90 v 0 h 100 v 90 q -50 -15 -100 0' fill='%23000000'/></svg>");
  mask-position: bottom;
  mask-size: max(100%, 1024px + 75%) 1024px;
  mask-repeat: no-repeat;
  mask-clip: border-box;
  mask-origin: border-box;
}

header.top:after {
  content: "";
  xheight: 240px;
  xbackground-color: #ff634766;
  xbackground-color: #aa2d2180;
  xbackground-color: #5e3a2a99;
  xbackground-color: #00000080;
  z-index: 13;
  pointer-events: none;
  background-color: #ff634759;
  width: 100%;
  position: absolute;
  top: 0;
  mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 85 100 5'><path d='m 0 90 q 50 -10 100 0 q -50 -8.5 -100 0' fill='%23000000'/></svg>");
  mask-position: bottom;
  mask-size: max(100%, 1024px + 75%) 100px;
  mask-repeat: no-repeat;
  mask-clip: border-box;
  mask-origin: border-box;
}

header.top div.top-shadow {
  content: "";
  xheight: 480px;
  xcolor: #fff0;
  xpointer-events: none;
  background-color: #00000080;
  justify-content: center;
  width: 100%;
  transition: all .2s ease-in-out;
  display: flex;
  position: absolute;
  top: 0;
  mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 90 100 90'><path d='m 0 90 v 0 h 100 v 90 q -50 -12 -100 0' fill='%23000000'/></svg>");
  mask-position: bottom;
  mask-size: max(100%, 1024px + 75%) 1024px;
  mask-repeat: no-repeat;
  mask-clip: border-box;
  mask-origin: border-box;
}

header.top:hover div.top-shadow, header.top:focus div.top-shadow, header.top:active div.top-shadow, header.top.active div.top-shadow {
  xheight: 480px;
  xheight: 520px;
  height: var(--top-shadow-height);
  xz-index: 11;
  xpointer-events: auto;
  background-color: #000000b3;
}

header.top div.top-shadow nav {
  xtransition: all .2s ease-in-out;
  display: none;
}

header.top:hover div.top-shadow nav, header.top:focus div.top-shadow nav, header.top:active div.top-shadow nav, header.top.active div.top-shadow nav {
  display: block;
}

nav {
  justify-content: center;
  align-self: flex-end;
  margin-bottom: 100px;
  display: flex;
}

nav li {
  text-align: center;
  list-style: none;
  display: block;
}

@media (width >= 813px) and (orientation: portrait), (width >= 769px) {
  nav li {
    display: inline-block;
  }

  h5.date-location {
    display: none;
  }
}

nav li > a {
  text-rendering: optimizelegibility;
  font-family: var(--head-font);
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 100;
  border-radius: 14px;
  margin-bottom: 4px;
  padding: 12px 17px 12px 19px;
  font-size: 1.5em;
  font-weight: 300;
  line-height: 1.2em;
  text-decoration: none;
  transition: all .1s ease-in-out;
  display: inline-block;
  position: relative;
  transform: scale(.975);
  color: #f0f0f0 !important;
  border: 1px solid #0000 !important;
  outline: 0 !important;
}

nav li > a:hover {
  xbox-shadow: inset #000 0px 1px 20px 0px;
  position: relative;
  transform: scale(1);
  box-shadow: inset 0 0 20px -4px #fff3;
  xbackground-color: #f0f0f00d !important;
  color: #fff !important;
  background-color: #0003 !important;
  border: 1px solid #f0f0f0 !important;
}

nav li > a:active {
  -webkit-box-shadow: inset 0 1px 2px #0000001a;
  -moz-box-shadow: inset 0 1px 2px #0000001a;
  box-shadow: inset 0 1px 2px #0003;
}

nav li > a:focus {
  background-color: #f0f0f033;
}

header.top div.top-inner-2 {
  text-align: center;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 130px;
  margin: 0 auto;
  display: flex;
  position: relative;
  overflow: hidden;
}

header.top div.top-inner-2 a {
  z-index: 1123;
  display: inline-block;
  position: relative;
}

header.top div.top-inner:before {
  content: "";
  xheight: 240px;
  text-align: center;
  background: linear-gradient(90deg, #000 0%, #2e170599 40% 60%, #000 100%), radial-gradient(#ec2f2f33 0%, #ff00001a 50%, #f000 75% 100%);
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  overflow: hidden;
}

header.top div.top-inner-2 h5 {
  opacity: .5;
  width: 200px;
  margin: 10px;
  font-size: 18px;
}

@media only screen and (width <= 812px) {
  header.top div.top-inner-2 h5 {
    display: none;
  }
}

@media only screen and (device-width <= 812px) {
  header.top div.top-inner-2 h5 {
    display: none;
  }
}

section {
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: stretch;
  width: 100%;
  min-height: max(100vh, 500px);
  margin-top: -90px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.section_title {
  margin-top: 1em;
}

section .inner {
  xdisplay: block;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  height: 100%;
  min-height: max(100vh, 500px);
  margin: 0 0 -30px;
  padding: 40px 20px 160px;
  position: relative;
  overflow: hidden;
  mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 15 100 2850'><path d='m 0 20 q 50 -10 100 0 v 2845 h -100 z' fill='%23000000'/></svg>");
  mask-position: top;
  mask-size: max(100%, 1024px + 62.5%) 50000px;
  mask-repeat: no-repeat;
  mask-clip: border-box;
  mask-origin: border-box;
}

section .background-image {
  z-index: -5;
  background-position: 50% 0;
  background-repeat: no-repeat;
  transition: opacity .3s ease-out;
  position: absolute;
  inset: -150px -150px 0;
  overflow: hidden;
  background-size: cover !important;
}

section .background-image:after {
  content: "";
  background-color: #140a02cc;
  position: absolute;
  inset: 0;
}

section article {
  xborder: 1px solid green;
  box-sizing: border-box;
  text-align: center;
  xbackground-color: #f0f0f0;
  xmargin-top: -240px;
  xpadding-top: 240px;
  min-width: 320px;
  max-width: 1080px;
  margin: 0 auto;
  display: block;
}

section article p {
  text-align: justify;
  align-self: center;
}

div.page img, article.page img {
  max-width: 100%;
}

section#intro {
  text-align: center;
  xxperspective: 10px;
  xxtransform-style: preserve-3d;
  width: 100%;
  margin-top: -180px;
  margin-bottom: -85px;
  padding-top: 95px;
  overflow: hidden;
}

section#intro .inner {
  align-items: stretch;
  display: flex;
  mask-image: none;
}

section#intro .inner article {
  xalign-items: stretch;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

section#intro .inner article h3 {
  margin-left: auto;
  margin-right: auto;
}

section.centered, section.centered .inner {
  align-items: center;
  display: flex;
  mask-image: none;
}

section#intro .background-image {
  transform: translateY(calc(var(--scrollPosition) / 2));
  background-color: #1f1d1b;
  background-image: url("../images/themazcal117-2560-50.e0ec5078.webp");
  background-position: 50% 0;
}

section#intro .background-image:after {
  background-color: #0000;
}

section#vizija .inner {
  background-color: #ff00001a;
}

section#vizija .background-image {
  xoverflow: hidden;
  xxbackground-position: calc(var(--scrollPosition) / -20) calc(var(--scrollPosition) / 20);
  xtransform: translateZ(0);
  transform: translateX(calc(var(--scrollPosition) / -20));
  background-color: #1f1d1b;
  background-image: url("../images/themazcal25-2560-60.ba2993e2.webp");
  background-position: top;
}

section#bilietai .background-image {
  transform: translateX(calc(var(--scrollPosition) / 40));
  background-image: url("../images/themazcal60-2560-60.6c8c0082.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

section#bilietai .background-image:after {
  background-color: #140a08cc;
}

section#moon {
  padding-top: 0;
}

section#moon .inner {
  background-color: #222;
  background-image: url("../images/moon-hi-FqexeBWakAA9jrg.8ceaad18.jpg");
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 0;
  padding-bottom: 40px;
  display: flex;
}

img.logo {
  width: 450px;
  max-width: 90vw;
  margin: 25px 0 10px;
}

img.bauba {
  max-width: 30vw;
  margin: 10px 20px;
}

div.page img.bs, article.page img.bs {
  max-width: min(40px, 10vw);
  margin: 57px 0 0;
}

div.logo-line {
  xborder: 1px solid red;
  z-index: 1;
  flex-flow: row;
  justify-content: center;
  width: 100%;
  margin: 0 auto 40px;
  display: flex;
  position: relative;
}

h3.social {
  xcolor: #5a4d42;
  z-index: 100;
  xheight: 90px;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  font-size: 28px;
  display: flex;
  position: relative;
}

.social a {
  border: none;
  margin: 8px;
  padding: 5px;
  text-decoration: none;
  transition: all .1s ease-in-out;
  display: inline-block;
  position: relative;
  transform: scale(.95);
}

.social a:visited, .social a:link {
  color: #79664d;
  xcolor: #f6c97b;
  border: none;
}

.social a:active, .social a:hover {
  color: #fff;
  border: none;
  transform: scale(1);
}

h3.social.bottom {
  align-self: flex-end;
  margin-bottom: 0;
}

x {
  color: #3b7022;
}

img.right, img.left, img.center {
  border: 0;
}

img.right {
  float: right;
  margin: 0 0 2em 2em;
}

img.left {
  float: left;
  margin: 0 2em 2em 0;
}

img.center {
  text-align: center;
  margin: 2em auto;
  display: block;
}

iframe.youtube {
  margin: 2em 0;
}
/*# sourceMappingURL=landing.a22f5005.css.map */
